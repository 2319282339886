import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Divider  = (props:any) => {
     const sectionsRef = useRef<HTMLDivElement[]>([]);

     useEffect(() => {
          gsap.registerPlugin(ScrollTrigger);

          // Scroll animation for each section
          sectionsRef.current.forEach((section, index) => {
               gsap.fromTo(
                    section,
                    
                    { 
                         y: "100%", // Starting position
                         height: "50px",
                     }, // Start with full height and space
                    {
                         height: "30px", // Gradually reduce the height
                         duration: 1,
                         scrollTrigger: {
                              trigger: section,
                              start: "top center", // When the top of the section reaches the center
                              end: "bottom center", // When the bottom of the section reaches the center
                              scrub: true, // Smooth scroll-triggered animation
                              markers: false, // Set to true if you want to see ScrollTrigger markers for debugging
                              onLeave: () => {
                                   // Once the last section reaches the end, its height becomes 0
                                   if (index === sectionsRef.current.length - 1) {
                                        gsap.to(section, { height: 0, opacity: 0, duration: 0.5 });
                                   }
                              },
                         },
                    }
               );
          });
     }, []);

     return (
          <section className="__divider">
               <div className="scroll-container">
                    {[...Array(6)].map((_, index) => (
                         <div
                              key={index}
                              className={`scroll-section ${index % 2 === 1 ? " --black" : "--white"}`}
                              ref={(el) => {
                                   if (el) sectionsRef.current[index] = el;
                              }}
                         >
                         </div>
                    ))}
               </div>
          </section>
     );
};

export default Divider;
