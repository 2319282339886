import site_logo from '../Assets/Image/logo.svg';
export const logo = site_logo;
export const title = 'Thedc Tech';
export const websiteName = 'none.c';
export const PasswordAddress = '000000';
export const linkedinLink = 'https://www.linkedin.com/in/devsunilsharma/';
export const instaLink = 'https://instagram.com/thedc__';


export const sendMail = () => {
    const email = 'thedc.here@gmail.com';
    const subject = 'Need Assistance with Your Services';
    const body = `Hi Thedc,

I hope this email finds you well! I recently came across your services on thedc.tech, and I must say, your tools are incredible.
 The SVG to Code and Gradient Color Generator have already saved me so much time in my projects.

However, I have a quick question and need your assistance. I'm trying to [briefly describe the issue or requirement here], and I feel your expertise could really guide me in the right direction.

Looking forward to hearing from you. I truly appreciate your support and the amazing resources you've created for developers like me.

[Your Information with service.info]

Best regards,
[Your Name]`;

    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(
        subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(gmailLink, '_blank');
};