import React, { useEffect, useState } from 'react';
import { logo, PasswordAddress, title } from '../Config/Config';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../Config/Loader';
import { getCookie, setCookie } from 'typescript-cookie';
import { ArrowBack } from '@mui/icons-material';
import HelmetSEO from '../Config/HelmetSEO';
// import toasted from '../Config/toast';

const Login = () => {


     const navigate = useNavigate();
     const [otpInputs, setOtpInputs] = useState(["", "", "", "", "", ""]);
     const [error, setError] = useState("");

     useEffect(() => {
          // Check if user is already authenticated
          const authCookie = getCookie('auth');
          if (authCookie === 'authenticated') {
               navigate('/');
          }

          // Update document meta tags for SEO
          document.title = `Login | ${title}`;
          document
               .querySelector('meta[name="description"]')
               ?.setAttribute(
                    "content",
                    "Log in to access your personalized dashboard and exclusive features. Secure and fast login for team members only."
               );

          return () => {
               // Cleanup meta tags if necessary
               document.title = title;
          };
     }, [navigate]);



     const handleInputChange = (value: string, index: number) => {
          // Allow only single digits
          if (/^\d?$/.test(value)) {
               const updatedOtpInputs = [...otpInputs];
               updatedOtpInputs[index] = value;
               setOtpInputs(updatedOtpInputs);

               // Automatically focus the next input if the current one is filled
               if (value !== "" && index < otpInputs.length - 1) {
                    const nextInput = document.getElementById(`otp-${index + 1}`);
                    nextInput?.focus();
               }
          }
     };

     const handleKeyDown = (event: React.KeyboardEvent, index: number) => {
          // Handle backspace key to clear the input and focus the previous field
          if (event.key === "Backspace" && otpInputs[index] === "") {
               if (index > 0) {
                    const previousInput = document.getElementById(`otp-${index - 1}`);
                    previousInput?.focus();
               }
          }
     };

     const handleLogin = (e: any) => {
          e.preventDefault(); // Prevent the page refresh

          const enteredPassword = otpInputs.join(""); // Combine inputs to form password
          if (enteredPassword === PasswordAddress) {
               setCookie("auth", "authenticated", { expires: 5 / 24 }); // Use empty string as context
               navigate("/code");
          } else {
               setError("Invalid Security code. Please try again.");
          }
     };

     return (
          <>
               {/* Loader */}
               <Loader timer={1000} />

               {/* Structured Data */}
               <script type="application/ld+json">
                    {JSON.stringify({
                         "@context": "https://schema.org",
                         "@type": "WebPage",
                         "name": "Login",
                         "description": "Log in to access your personalized dashboard and exclusive features. Secure and fast login for team members only.",
                         "url": window.location.href
                    })}
               </script>

               <HelmetSEO
                    title={`Login | ${title}`}
                    description="Welcome to our Thedc Team Login! Log in to access your personalized dashboard and exclusive features. Secure and fast login for team members only"
                    keywords="login, typescript, website designer, frontend developer, Portfolio website , sunil sharma,"
               />

               <div className="__login">
                    <div className="__contaer">
                         <div className="__logo">
                              <Link to="/">
                                   <img src={logo} alt="Logo of the website" />
                              </Link>
                              <h4 className='color-txt'>Team Members' Exclusive Login</h4>
                              <p>Access is limited to the core team members only. Ensure you use your credentials to unlock tools  and resources tailored for your work.</p>
                         </div>
                         <form className="form" onSubmit={handleLogin}>
                              <div className="row">
                                   <div className="col-12">
                                        <div className="from-group">
                                             <h5>Security code</h5>
                                             <p className='__error'>{error}</p>

                                             <div className='__inputs'>
                                                  {otpInputs.map((value, index) => (
                                                       <input
                                                            key={index}
                                                            id={`otp-${index}`}
                                                            type="text"
                                                            inputMode="numeric"
                                                            maxLength={1}
                                                            value={value}
                                                            onChange={(e) => handleInputChange(e.target.value, index)}
                                                            onKeyDown={(e) => handleKeyDown(e, index)} />
                                                  ))}
                                             </div>
                                        </div>
                                   </div>

                                   <div className="col-12">
                                        <div className="from-group from-btn">
                                             <Link to="/" className='btn btn-back' aria-label="go back homepage button">   <ArrowBack /></Link>
                                             <button type="submit" className="btn btn-common" aria-label="Log in to your account"> Login </button>
                                        </div>
                                   </div>
                              </div>
                         </form>
                    </div>
               </div>
          </>
     );
};

export default Login;
