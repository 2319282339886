import React from 'react';

const Task = () => {

    // none 
    // https://mbilalkhan.com/

    // https://lorem.space/
    // https://uigenerator.org/?type=0&category=4&dark=1&color=7
    // https://lyricsum.com/
    // https://app.hatom.com/
    // https://engaged.be/#
    // https://www.filmsecession.com/exhibition/color
    // https://www.poppr.be/en
    // https://www.thoughtlab.com/
    // https://engaged.be/

    // portfollio copy is
    //  1. https://www.david-hckh.com/
    // https://codesandbox.io/p/sandbox/tu24h
    // https://codesandbox.io/p/sandbox/zgsyn?file=%2Fsrc%2FApp.js
    // https://www.autovrse.com/

    // https://codesandbox.io/p/sandbox/github/Sinncity/Camera-sandbox/tree/main/ website ke homepage background


    // https://reactjsexample.com/10-best-react-restaurant-website-templates/ demo or example...
    // https://poly.pizza/m/7cvx6ex-xfL

    // https://www.w3schools.com/colors/colors_psychology.asp
    // https://www.mysitemapgenerator.com/
    const tasks = [
        {
            title: 'Glassmorphism CSS Generator',
            description: 'Get started with this free CSS generator based on the glassmorphism design specifications to quickly design and customize the style properties.',
            link: 'https://ui.glass/generator/'
        },
        {
            title: 'Blobmaker is a free generative design tool',
            description: 'Blobmaker is a free generative design tool made with 💕 by z creative labs, to help you quickly create random, unique, and organic-looking SVG shapes.',
            link: 'https://www.blobmaker.app/'
        },
        {
            title: 'CSS Grid Generator',
            description: 'Simple and easy way CSS Grid Generator',
            link: 'https://cssgrid-generator.netlify.app/'
        },
    ];

    return (
        <div className='task'>
            <div className='__container'>

                <h1> All Task</h1>


                {/* {tasks.map((task, index) => (
                    <div key={index} className='card-task'>
                        <div>
                            <h4> {index + 1}. {task.title}</h4>
                            <p>{task.description}</p>
                        </div>
                        <a href={task.link} target="_blank" rel="noopener noreferrer">
                            Reference Link
                        </a>
                    </div>
                ))} */}


                
            </div>

        </div>
    );
}

export default Task;
