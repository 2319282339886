import React, { useEffect, useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { instaLink, linkedinLink, title } from '../../Config/Config';
import { Link } from 'react-router-dom';
import Loader from '../../Config/Loader';
import HelmetSEO from '../../Config/HelmetSEO';

export const fetchColors = async (colorName: string, number: number = 500) => {
     try {
          const response = await fetch(`https://x-colors.yurace.pro/api/random/${colorName}/?number=${number}`);
          if (!response.ok) {
               throw new Error("Failed to fetch colors");
          }
          const data = await response.json();
          return data;
     } catch (error) {
          throw error;
     }
};

interface Color {
     hex: string;
     rgb: string;
     hsl: string;
}

const ColosTool = () => {
     const [colors, setColors] = useState<Color[]>([]);
     const [colorName, setColorName] = useState<string>('red');
     const [copiedColor, setCopiedColor] = useState<string | null>(null);


     const handleColorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
          setColorName(e.target.value); // Update selected color name
     };

     useEffect(() => {
          const getColors = async () => {
               try {
                    const data = await fetchColors(colorName, 2500);

                    // Filter duplicates using a Set
                    const seen = new Set<string>();
                    const filteredColors = data.filter((color: Color) => {
                         if (!seen.has(color.hex)) {
                              seen.add(color.hex);
                              return true;
                         }
                         return false;
                    });

                    setColors(filteredColors);
               } catch (err) {
                    console.error("Failed to load colors:", err);
               }
          };

          getColors();
     }, [colorName]); // Runs every time colorName changes

     const number = colors.length;
     const formattedNumber = number.toLocaleString();


     // Function to copy color HEX code
     const handleCopyColor = (hex: string) => {
          navigator.clipboard.writeText(hex).then(() => {
               setCopiedColor(hex);
               // Reset copied message after 1.5 seconds
               setTimeout(() => setCopiedColor(null), 1500);
          });
     };

     return (
          <>

               <HelmetSEO
                    title={`Colors | ${title}`}
                    description="Explore an extensive list of HTML color codes with HEX, RGB, and HSL values. Find your perfect shade of red, blue, green, and more. Copy colors instantly for web design, graphic design, and development projects."
                    keywords="thedc , thedc.tech, HTML color codes, color picker, HEX codes, RGB values, HSL colors, web design colors, graphic design colors, color palette generator, red color codes, blue color codes, green color codes, copy HEX codes, color chart, CSS color codes, free color tools"
               />

               <Loader timer={200} />

               <div className='__gradient-qw1'>
                    <ParallaxProvider>
                         <div className='__container'>
                              <div className='qw-gradinet-heading'>
                                   <h3 className='_headingkj' style={{ color: colorName }}>Explore +{formattedNumber} HTML Color Codes</h3>
                                   <p>Hover over any card to see the color code.</p>
                                   <p>Click on a card to copy the color code to your clipboard instantly</p>
                                   <p>Follow me on
                                   <Link to={linkedinLink} title='LinkedIn' style={{ color: colorName }} target='_blank'> @sunilsharma</Link> and <Link title='Instagram' to={instaLink} style={{ color: colorName }} target='_blank'> @tᖾᥱᑯᥴ_</Link> for more useful tools and updates."
                                       
                                        
                                   </p>
                              </div>

                              {/* New Selector Section */}
                              <div className='___selector'>
                                   <label htmlFor="colorSelector">Choose a Color Category</label>
                                   <select className='form-control' id="colorSelector" value={colorName}
                                        style={{
                                             border: `0.4px solid ${colorName}`, // Dynamic border on focus
                                             transition: "border-color 0.3s ease-in-out",
                                        }}
                                        onChange={handleColorChange}>
                                        <option value="red">Red</option>
                                        <option value="green">Green</option>
                                        <option value="blue">Blue</option>
                                        <option value="yellow">Yellow</option>
                                        <option value="purple">Purple</option>
                                        <option value="aqua">aqua</option>
                                        <option value="lime">lime</option>
                                        <option value="orange">orange</option>
                                        <option value="navy">navy</option>
                                        <option value="pink">pink</option>
                                        <option value="random">random</option>

                                   </select>
                              </div>

                              {/* Colors Grid */}
                              <div className='__qw-section'>
                                   <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(90px, 1fr))", gap: "10px" }}>
                                        {colors.map((color, index) => (
                                             <div key={index}
                                                  onClick={() => handleCopyColor(color.hex)} style={{ backgroundColor: color.hex }} className='__card_9iks shadow'>
                                                  <p className='id_9iks'>{(index + 1).toLocaleString()} {copiedColor === color.hex && "Copied!"}</p>
                                                  <p className='color_9iks'>{color.hex}</p>

                                             </div>
                                        ))}
                                   </div>
                              </div>
                         </div>
                    </ParallaxProvider>
               </div>
          </>
     );
}

export default ColosTool;