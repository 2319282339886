import { KeyboardArrowDown } from '@mui/icons-material';
import React from 'react'
import Accordion from 'react-bootstrap/Accordion';


const Faq = () => {

    const faqData = [
        {
            question: "What services do you offer?",
            answer: "other than web design and webflow development, i do brand strategy and brand identity design. based on your needs, i am also able to put a small team together to solve the specific needs of your project."
        },
        {
            question: "How long does it take?",
            answer: "it depends, but usually most of my projects take between 3 weeks and 3 months."
        },
        {
            question: "How much does it cost?",
            answer: "every project is different, so the price varies. although my project usually range your confrotable budget."
        },
        {
            question: "What does working together look like?",
            answer: "i put emphasis on the strategy at the start, so i can really understand your specific needs and i can be more efficient at solving the challenge later on. i like to keep my clients often updated on the project. that's why you will have access to your personal project dashboard to see exactly where the project is."
        },
        {
            question: "Will I like working with you?",
            answer: "i hope so! but if you want a glimps of how it could be, i can connect you to multiple of my past clients, so you can ask them directly how it was to work with me."
        },
    ];

    return (
        <div className='__faq'>
            <div className='__wapper'>
                <div className='__faq-header'>
                    <h4> Faq </h4>
                    <p>Have a question? Look at our frequently asked questions below.</p>
                </div>

                <div className='__faq-center'>
                    <Accordion>
                        {faqData.map((faq, index) => (
                            <Accordion.Item eventKey={index.toString()} key={index}>
                                <Accordion.Header>
                                    <h5>{faq.question}</h5>
                                    <KeyboardArrowDown/>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>{faq.answer}</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>

            </div>
        </div>
    )
}

export default Faq