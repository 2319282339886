import React  from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import './Assets/scss/style.scss';
import Header from './Layout/Header';
import Index from './Public/Index';
import Login from './User/Login';
import Overview from './Private/Overview';
import PrivateRoutes from './Config/PrivateRoutes';
import { Toaster } from 'react-hot-toast';
import HelpAi from './Public/Ai/HelpAi';
import Coding from './Public/Coding';
import AboutPage from './Public/About';
import View from './Me/View';
import GradientMeker from './Tools/Gradients/GradientMeker';
import ColosTool from './Tools/Gradients/ColosTool';
import Task from './Public/TaskManager/Task';
import BlogPage from './Public/BlogPage';
import License from './Public/License';
import Footer from './Layout/Footer';
import Demo from './Public/Demo';
import Demo2 from './Public/Demo2';
import Demo3 from './Public/Demo3';
import Snake from './Private/GamesMenu/Snake';
// designing creatives


function App() {




    // Public page.....
    function PublicLayout() {
        return (
            <>
                <Header />
                <Outlet />
                <Footer/>
            </>
        );
    }

    function PrivateLayout() {
        return (
            <>
                <Header />
                <Outlet />
                <Footer/>
            </>
        );
    }


    // Login page.....
    function LoginLayout() {
        return (
            <Outlet />
        );
    }



    return (

        <Router>
            <Routes>
                <Route element={<LoginLayout />}>
                    <Route path='/login' element={<Login />} />
                </Route>

                <Route element={<PublicLayout />}>
                    <Route path='/' element={<Index />} />
                    <Route path='/license' element={<License />} />
                    <Route path='/blog' element={<BlogPage />} />
                    <Route path='/about' element={<AboutPage />} />
                    <Route path='/websites' element={<HelpAi />} />
                    <Route path='/me' element={<View />} />
                    <Route path='/demo' element={<Demo />} />
                    <Route path='/demo2' element={<Demo2 />} />
                    <Route path='/demo3' element={<Demo3 />} />
                    <Route path='/gradients' element={<GradientMeker />} />
                    <Route path='/colors' element={<ColosTool />} />
                    <Route path='/task' element={<Task />} />
                    <Route path='/view' element={<Overview />} />
                    <Route path='/snake' element={<Snake />} />

                    {/* <Route path='/lines' element={<SvgPath />} /> */}
                </Route>




                <Route element={<PrivateLayout />}>
                    <Route element={<PrivateRoutes />}>
                        <Route path='/code' element={<Coding />} />
                    </Route>
                </Route>
            </Routes>
            <Toaster />
        </Router>

        
    );
}

export default App;
