import React, { useEffect, useRef, useState } from 'react';
import Matter from 'matter-js';

const containerValuesConfig = {
     low: {
          8: [5.6, 2.1, 1.1, 1, 0.5, 1, 1.1, 2.1, 5.6],
          9: [5.6, 2, 1.6, 1, 0.7, 0.7, 1, 1.6, 2, 5.6],
          10: [8.9, 3, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 3, 8.9],
          11: [8.4, 3, 1.9, 1.3, 1, 0.7, 0.7, 1, 1.3, 1.9, 3, 8.4],
          12: [10, 3, 1.6, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 1.6, 3, 10],
          13: [8.1, 4, 3, 1.9, 1.2, 0.9, 0.7, 0.7, 0.9, 1.2, 1.9, 3, 4, 8.1],
          14: [7.1, 4, 1.9, 1.4, 1.3, 1.1, 1, 0.5, 1, 1.1, 1.3, 1.4, 1.9, 4, 7.1],
          15: [15, 8, 3, 2, 1.5, 1.1, 1, 0.7, 0.7, 1, 1.1, 1.5, 2, 3, 8, 15],
          16: [16, 9, 2, 1.4, 1.4, 1.2, 1.1, 1, 0.5, 1, 1.1, 1.2, 1.4, 1.4, 2, 9, 16],
     },
     medium: {
          8: [13, 3, 1.3, 0.7, 0.4, 0.7, 1.3, 3, 13],
          9: [18, 4, 1.7, 0.9, 0.5, 0.5, 0.9, 1.7, 4, 18],
          10: [22, 5, 2, 1.4, 0.6, 0.4, 0.6, 1.4, 2, 5, 22],
          11: [24, 6, 3, 1.8, 0.7, 0.5, 0.5, 0.7, 1.8, 3, 6, 24],
          12: [33, 11, 4, 2, 1.1, 0.6, 0.3, 0.6, 1.1, 2, 4, 11, 33],
          13: [43, 13, 6, 3, 1.3, 0.7, 0.4, 0.4, 0.7, 1.3, 3, 6, 13, 43],
          14: [58, 15, 7, 4, 1.9, 1, 0.5, 0.2, 0.5, 1, 1.9, 4, 7, 15, 58],
          15: [88, 18, 11, 5, 3, 1.3, 0.5, 0.3, 0.3, 0.5, 1.3, 3, 5, 11, 18, 88],
          16: [110, 41, 10, 5, 3, 1.5, 1, 0.5, 0.3, 0.5, 1, 1.5, 3, 5, 10, 41, 110],
     },
     high: {
          8: [29, 4, 1.5, 0.3, 0.2, 0.3, 1.5, 4, 29],
          9: [43, 7, 2, 0.6, 0.2, 0.2, 0.6, 2, 7, 43],
          10: [76, 10, 3, 0.9, 0.3, 0.2, 0.3, 0.9, 3, 10, 76],
          11: [120, 14, 5.2, 1.4, 0.4, 0.2, 0.2, 0.4, 1.4, 5.2, 14, 120],
          12: [170, 24, 8.1, 2, 0.7, 0.2, 0.2, 0.2, 0.7, 2, 8.1, 24, 170],
          13: [260, 37, 11, 4, 1, 0.2, 0.2, 0.2, 0.2, 1, 4, 11, 37, 260],
          14: [420, 56, 18, 5, 1.9, 0.3, 0.2, 0.2, 0.2, 0.3, 1.9, 5, 18, 56, 420],
          15: [620, 83, 27, 8, 2, 0.5, 0.2, 0.2, 0.2, 0.2, 0.5, 3, 8, 27, 83, 620],
          16: [1000, 130, 26, 9, 4, 2, 0.2, 0.2, 0.2, 0.2, 0.2, 2, 4, 9, 26, 130, 1000],
     }
};

type Difficulty = 'low' | 'medium' | 'high';
type Rows = 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;

const Demo3: React.FC = () => {
     const canvasRef = useRef<HTMLDivElement>(null);
     const [rows, setRows] = useState<Rows>(8);
     const [winningState, setWinningState] = useState(0);
     const [containerValues, setContainerValues] = useState(containerValuesConfig.low[8]);
     const [difficulty, setDifficulty] = useState<Difficulty>('low');
     const engineRef = useRef<Matter.Engine | null>(null);
     const ballRef = useRef<Matter.Body | null>(null);

     // Calculate Common variables 
     const inWidth = 1400; 
     const inHeight = 718;
     const minBallRadius = 7;
     const maxBallRadius = 10;
     const ballRadius = maxBallRadius - ((rows - 8) * (maxBallRadius - minBallRadius)) / 8;
     const containerHeight = 35;
     const groundHeight = containerHeight / 2;

     // Determine the color of the ball based on the difficulty
     const getBallColor = (difficulty: Difficulty) => {
          switch (difficulty) {
               case 'low':
                    return 'yellow';
               case 'medium':
                    return 'orange';
               case 'high':
                    return 'red';
               default:
                    return 'red';
          }
     };

     useEffect(() => {
          // Get the rows and difficulty from localStorage on mount, if available
          const savedRows = localStorage.getItem('rows');
          if (savedRows) {
               setRows(Number(savedRows) as Rows); // Set the rows state to the saved value
          }
          const savedDifficulty = localStorage.getItem('difficulty');
          if (savedDifficulty) {
               setDifficulty(savedDifficulty as Difficulty); // Set the difficulty state to the saved value
          }
     }, []);

     useEffect(() => {
          // Update container values based on rows and difficulty
          setContainerValues(containerValuesConfig[difficulty][rows]);
     }, [rows, difficulty]);

     useEffect(() => {
          // Module aliases
          const { Engine, Render, World, Bodies, Runner, Events, Body, Vertices } = Matter;

          // Create an engine
          const engine = Engine.create({
               gravity: { x: 0, y: 0.5 }, // Enable gravity
          });
          engineRef.current = engine;

          // Create a runner
          const runner = Runner.create();

          // Create a renderer
          const render = Render.create({
               element: canvasRef.current!,
               engine: engine,
               options: {
                    width: inWidth, // Full screen width
                    height: inHeight, // Full screen height
                    wireframes: false, // Use solid rendering
                    background: '#f0f0f0', // Set a light background
               },
          });


          // Create the ground
          const ground = Bodies.rectangle(
               inWidth / 2,
               inHeight - 1,
               inWidth,
               groundHeight,
               {
                    isStatic: true,
                    render: { fillStyle: 'green' },
               }
          );

          // Function to create circles in a triangle shape
          const createCirclesInTriangle = (rows: number) => {
               const circles: Matter.Body[] = [];
               const initialY = 100; // Center of the canvas height
               let offsetY = 0;

               // Calculate dynamic spacing
               const baseHorizontalSpacing = 41; // Base horizontal spacing
               const baseVerticalSpacing = 47; // Base vertical spacing
               const horizontalSpacing = baseHorizontalSpacing - (rows - 8) * 1; // Adjusted horizontal spacing
               const verticalSpacing = baseVerticalSpacing - (rows - 8) * 3.5; // Adjusted vertical spacing

               for (let row = 3; row <= rows + 2; row++) {
                    const numCirclesInRow = row;
                    const offsetX = (numCirclesInRow - 1) * (ballRadius * 2 + horizontalSpacing);

                    const startX = (inWidth - offsetX) / 2;

                    for (let i = 0; i < numCirclesInRow; i++) {
                         const circle = Bodies.circle(
                              startX + i * (ballRadius * 2 + horizontalSpacing),
                              initialY + offsetY,
                              ballRadius,
                              {
                                   isStatic: true,
                                   render: { fillStyle: 'pink' },
                              }
                         );
                         circle.render.strokeStyle = '#f0f0f0'; // Add border for animation
                         circle.render.lineWidth = 2;
                         circles.push(circle);
                    }
                    offsetY += ballRadius * 2 + verticalSpacing;
               }
               return circles;
          };

          const circles = createCirclesInTriangle(rows);

          World.add(engine.world, [ground, ...circles]);

          // Calculate the width of the bottom row of circles
          const calculatedBottomRowWidth = (rows + 2 - 1) * (ballRadius * 2 + 40);

          // Create containers just above the ground
          const createContainers = (rows: number) => {
               const containers: Matter.Body[] = [];
               const containerWidth = calculatedBottomRowWidth / (rows + 1) - 5; // Subtracting margin space


               for (let i = 0; i <= rows; i++) {
                    const container = Bodies.rectangle(
                         containerWidth * i + containerWidth / 2 + (inWidth - calculatedBottomRowWidth) / 2 + i * 5, // Adding margin space
                         inHeight - groundHeight - containerHeight / 2,
                         containerWidth,
                         containerHeight,
                         {
                              isStatic: true,
                              render: { fillStyle: 'blue' },
                         }
                    );
                    // container.label = `Container-${i}`;
                    containers.push(container);
               }
               return containers;
          };

          const containers = createContainers(rows);
          World.add(engine.world, containers);

          // Add an event listener for collisions
          Events.on(engine, 'collisionStart', (event) => {
               if (!ballRef.current) return; // Ensure the ball reference is available

               event.pairs.forEach(({ bodyA, bodyB }) => {
                    const ball = ballRef.current;

                    // Prevent bouncing behavior on ground touch
                    if ((bodyA === ball && bodyB === ground) || (bodyB === ball && bodyA === ground)) {
                         Body.setVelocity(ball, { x: 0, y: 0 });
                         Body.setPosition(ball, { x: ball.position.x, y: window.innerHeight - ballRadius - 1 });
                    }

                    // Prevent bouncing behavior on container touch
                    containers.forEach((container, index) => {
                         if ((bodyA === ball && bodyB === container) || (bodyB === ball && bodyA === container)) {
                             Body.setVelocity(ball, { x: 0, y: 0 });
                             setWinningState(containerValues[index]); // Set the winning state to the corresponding value
                             console.log(`Ball fell into container ${index + 1} with value ${containerValues[index]}`);
                     
                             // Change the color of the container to red
                             container.render.fillStyle = 'red';
                     
                             // Optionally, you can revert the color back to blue after a certain time
                         //     setTimeout(() => {
                         //         container.render.fillStyle = 'blue';
                         //     }, 1000); // Revert back to blue after 1 second
                         }
                     });

                    // Inside the collision event listener, when a circle is hit:
                    circles.forEach((circle) => {
                         if ((bodyA === ball && bodyB === circle) || (bodyB === ball && bodyA === circle)) {
                              // Change circle color to black
                              circle.render.fillStyle = '#000';

                              // Add border wave animation
                              circle.render.strokeStyle = 'red';
                              circle.render.lineWidth = 3;
                              setTimeout(() => {
                                   circle.render.fillStyle = 'pink';
                                   circle.render.lineWidth = 0;
                                   circle.render.strokeStyle = '#ddd';
                              }, 1000); // Remove the border after 1.5 seconds
                         }
                    });
               });
          });

          // Add an event listener for afterRender to draw text on the canvas
          Events.on(render, 'afterRender', () => {
               const context = render.context;
               context.font = '14px Poppins'; // Set the font properties
               context.fillStyle = 'white'; // Set the text color
               context.textAlign = 'center'; // Center the text

               if (containerValues) {
                    containers.forEach((container, index) => {
                         if (containerValues[index] !== undefined) {
                              const { position } = container;
                              context.fillText(`${containerValues[index]}x`, position.x, position.y);
                         }
                    });
               }
          });

          Runner.run(runner, engine);
          Render.run(render);

          return () => {
               Render.stop(render);
               Runner.stop(runner);
               World.clear(engine.world, false); // Fixed issue here
               Engine.clear(engine);
               render.canvas.remove();
          };
     }, [rows, containerValues]);

     const handleRowChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
          const selectedRows = Number(event.target.value) as Rows;
          setRows(selectedRows);
          localStorage.setItem('rows', selectedRows.toString());
     };

     const handleDifficultyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
          const selectedDifficulty = event.target.value as Difficulty;
          setDifficulty(selectedDifficulty);
          localStorage.setItem('difficulty', selectedDifficulty);
     };

     const dropBall = () => {
          if (!engineRef.current) return;
          const { Bodies, World } = Matter;

          // Calculate a random x position within the top row of the triangle
          const topRowWidth = (3 - 1) * (ballRadius * 2 + 10); // Assuming the top row has 3 circles
          const startX = (inWidth - topRowWidth) / 2;
          const randomX = startX + Math.random() * topRowWidth;

          const ballColor = getBallColor(difficulty);

          // Create a new ball
          const ball = Bodies.circle(randomX, 60, ballRadius * 1.1, {
               restitution: 0.5, // Bounciness for the ball, only applies on dots
               render: { fillStyle: ballColor },
          });

          ballRef.current = ball; // Store the ball reference
          World.add(engineRef.current.world, ball);
     };


     return (
          <div className="scene-container">
               <div className="controls">
                    <p>Current Winning Value: {winningState}</p><br />
                    <label htmlFor="row-selector">Select Number of Rows: </label>
                    <select id="row-selector" value={rows} onChange={handleRowChange}>
                         {[8, 9, 10, 11, 12, 13, 14, 15, 16].map((value) => (
                              <option key={value} value={value}>
                                   {value}
                              </option>
                         ))}
                    </select>
                    <br /><br /><br />
                    <label htmlFor="difficulty-selector">Select Difficulty: </label>
                    <select id="difficulty-selector" value={difficulty} onChange={handleDifficultyChange}>
                         <option value="low">Low</option>
                         <option value="medium">Medium</option>
                         <option value="high">High</option>
                    </select>
                    <br /><br /><br />
                    <button onClick={dropBall}>Drop Ball</button>
               </div>

               <div className='__game-outer'>
                    <div className="scene-wrapper" ref={canvasRef}>
                         {/* The main content here */}
                    </div>
               </div>
          </div>
     );
};

export default Demo3;