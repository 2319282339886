import { CopyAll } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import toasted from '../../Config/toast';
import { instaLink, linkedinLink, title } from '../../Config/Config';
import { initialGradients } from './GradientData';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Loader from '../../Config/Loader';




const GradientMeker = () => {

     const rendomarray = (array: any[]) => {
          return array
               .map((item) => ({ item, sort: Math.random() }))
               .sort((a, b) => a.sort - b.sort)
               .map(({ item }) => item);
     };

     const [gradients, setGradients] = useState(rendomarray(initialGradients));

     const updateGradient = (type: string, direction: string, colors: string[]) => {
          switch (type) {
               case 'linear-gradient':
                    return `${type}(${direction}, ${colors.join(', ')})`;
               case 'radial-gradient':
                    return `${type}(${direction}, ${colors.join(', ')})`;
               case 'conic-gradient':
                    return `${type}(${direction}, ${colors.join(', ')})`;
               default:
                    return '';
          }
     };

     const handleGradientChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>, id: any, isTypeChange: boolean = false) => {
          const value = e.target.value;

          setGradients(gradients.map(gradient => {
               if (gradient.id === id) {
                    const newType = isTypeChange ? value : gradient.type;
                    const newDirection = !isTypeChange ? value : newType === 'linear-gradient' ? 'to top' : 'at center';

                    return { ...gradient, type: newType, direction: newDirection };
               }
               return gradient;
          }));
     };

     // Copy gradient to clipboard 
     const handleCopy = (bgcode: any, gradientId: number) => {
          const copytxt = `background-image: ${bgcode};`;
          navigator.clipboard.writeText(copytxt);
          toasted.success(`copied #${gradientId} to clipboard!`);
     };



     return (

          <>
               <Loader timer={200} />
               <div className='__gradient-qw1'>
                    <ParallaxProvider>
                         <div className='__container'>
                              <div className='qw-gradinet-heading'>
                                   <h2>{title} Explore Vibrant {gradients.length - 1}+ Gradients</h2>
                                   <p>Our team creates useful tools for web designers and Graphic designer</p>
                                   <p>A collection of beautiful CSS gradients. Click to copy</p>
                                   <p> Created by <Link to={linkedinLink} title='linkdin' target='_blank'>@sunilsharma</Link> or <Link title='Instagram' to={instaLink} target='_blank'>@tᖾᥱᑯᥴ_</Link></p>
                                   {/* <a className='__producthunt-in' href="https://www.producthunt.com/products/thedc-gradients-colos?utm_source=badge-follow&utm_medium=badge&utm_souce=badge-thedc&#0045;gradients&#0045;colos" target="_blank">
                                        <img src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg?product_id=609025&theme=light" alt="Thedc&#0032;Gradients&#0032;Colos - Transform&#0032;Your&#0032;Design&#0032;Vision&#0032;with&#0032;Bold&#0032;Gradients | Product Hunt" style={{ width: '250px', height: '54px' }} width="250" height="54" />
                                   </a> */}

                              </div>

                              <div className='__qw-section'>
                                   <div className='row justify-content-center'>
                                        {gradients.map((gradient, index) => {
                                             const gradientBg = updateGradient(gradient.type, gradient.direction || 'to top', gradient.colors);
                                             const gradientId = index + 1;
                                             return (
                                                  <div key={index} className='col-lg-3 col-md-4 col-6'>
                                                       <Parallax speed={index % 2 === 0 ? 0 : 8}>
                                                            <div className='__qw-card-gradient'>
                                                                 <div className='__gredient-bg' style={{ backgroundImage: gradientBg }}>
                                                                      {/* Modern Gradient Colors */}
                                                                 </div>

                                                                 <div className='__more-sec'>
                                                                      <p>#{index + 1} <br /> Gradient Colors</p>
                                                                 </div>

                                                                 {/* Radio buttons for gradient type */}
                                                                 <div className='typs-gradient'>
                                                                      <label>
                                                                           <input type='radio' value='linear-gradient' checked={gradient.type === 'linear-gradient'}
                                                                                onChange={(e: any) => handleGradientChange(e, gradient.id, true)} />
                                                                           Linear
                                                                      </label>
                                                                      <label>
                                                                           <input
                                                                                type='radio'
                                                                                value='radial-gradient'
                                                                                checked={gradient.type === 'radial-gradient'}
                                                                                onChange={(e: any) => handleGradientChange(e, gradient.id, true)}
                                                                           />
                                                                           Radial
                                                                      </label>
                                                                      <label>
                                                                           <input type='radio' value='conic-gradient' checked={gradient.type === 'conic-gradient'}
                                                                                onChange={(e: any) => handleGradientChange(e, gradient.id, true)} /> Conic
                                                                      </label>
                                                                 </div>

                                                                 <div className='__gradient-tools'>
                                                                      {/* Select for gradient direction */}
                                                                      {gradient.type === 'linear-gradient' && (
                                                                           <select className='form-select' value={gradient.direction || 'to top'}
                                                                                onChange={(e) => handleGradientChange(e, gradient.id)}>
                                                                                <option value="to top">To Top</option>
                                                                                <option value="to top right">To Top Right</option>
                                                                                <option value="to right">To Right</option>
                                                                                <option value="to bottom right">To Bottom Right</option>
                                                                                <option value="to bottom">To Bottom</option>
                                                                                <option value="to bottom left">To Bottom Left</option>
                                                                                <option value="to left">To Left</option>
                                                                                <option value="to top left">To Top Left</option>
                                                                           </select>
                                                                      )}

                                                                      {gradient.type === 'conic-gradient' && (
                                                                           <select className='form-select' value={gradient.direction || 'at center'}
                                                                                onChange={(e) => handleGradientChange(e, gradient.id)}>
                                                                                <option value="at center">Conic Center</option>
                                                                                <option value="at center top">Conic Top</option>
                                                                                <option value="at right top">Conic Top Right</option>
                                                                                <option value="at right center">Conic Right</option>
                                                                                <option value="at right bottom">Conic Bottom Right</option>
                                                                                <option value="at center bottom">Conic Bottom</option>
                                                                                <option value="at left bottom">Conic Bottom Left</option>
                                                                                <option value="at left center">Conic Left</option>
                                                                                <option value="at left top">Conic Top Left</option>
                                                                           </select>
                                                                      )}

                                                                      {gradient.type === 'radial-gradient' && (
                                                                           <select className='form-select' value={gradient.direction || 'at center'}
                                                                                onChange={(e) => handleGradientChange(e, gradient.id)}>
                                                                                <option value="at center">Radial Center</option>
                                                                                <option value="at center top">Radial Top</option>
                                                                                <option value="at right top">Radial Top Right</option>
                                                                                <option value="at right center">Radial Right</option>
                                                                                <option value="at right bottom">Radial Bottom Right</option>
                                                                                <option value="at center bottom">Radial Bottom</option>
                                                                                <option value="at left bottom">Radial Bottom Left</option>
                                                                                <option value="at left center">Radial Left</option>
                                                                                <option value="at left top">Radial Top Left</option>
                                                                           </select>
                                                                      )}

                                                                      <button type='button' className='btn' onClick={() => handleCopy(gradientBg, gradientId)}>
                                                                           <CopyAll />
                                                                      </button>
                                                                 </div>
                                                            </div>
                                                       </Parallax>
                                                  </div>
                                             );
                                        })}
                                   </div>
                              </div>
                         </div>
                    </ParallaxProvider>
               </div>

          </>

     );
};

export default GradientMeker;
