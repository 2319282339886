import React from 'react'
import { Link } from 'react-router-dom'
import airbnbIcon from '../../Assets/icon/airbnb.svg';
import appleIcon from '../../Assets/icon/apple.svg';
import disneyIcon from '../../Assets/icon/disney.svg';
import dropboxIcon from '../../Assets/icon/dropbox.svg';
import eaIcon from '../../Assets/icon/ea.svg';
import microsoftIcon from '../../Assets/icon/microsoft.svg';
import netflixIcon from '../../Assets/icon/netflix.svg';
import ubisoftIcon from '../../Assets/icon/ubisoft.svg';
import workaiIcon from '../../Assets/icon/workai.svg';

const FavouriteWeb = () => {

    const topCompanies = [
        { name: 'WorkAI', icon: workaiIcon, link: 'https://www.dreamworks.com/'},
        { name: 'Airbnb', icon: airbnbIcon, link: 'https://www.airbnb.co.in/' },
        { name: 'Facebook', icon: appleIcon, link: 'https://www.apple.com/', },
        { name: 'Disney', icon: disneyIcon, link: 'https://www.disney.com/', },
        { name: 'Dropbox', icon: dropboxIcon, link: 'https://www.dropbox.com/', },
        { name: 'EA', icon: eaIcon, link: 'https://www.ea.com'},
        { name: 'Microsoft', icon: microsoftIcon, link: 'https://www.microsoft.com'},
        { name: 'Netflix', icon: netflixIcon, link: 'https://www.netflix.com' },
        { name: 'Ubisoft', icon: ubisoftIcon, link: 'https://www.ubisoft.com'},
    ];

    return (
        <>
            {/* Favourite Websites  */}
            <section className='__favourite_web'>
                <h2 className='section-heading'>Used by 5+ million designers and by top companies</h2>

                <div className='__wapper'>
                    <div className='__list'>
                        {topCompanies.map((company, index) => (
                            <Link to={company.link} target="_blank" rel="noopener noreferrer" key={index} className="company-item">
                                <img src={company.icon} alt={company.name} className="company-icon" />
                            </Link>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default FavouriteWeb