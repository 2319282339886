import React, { useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from "three"; // Import THREE explicitly

const Ball: React.FC = () => {
    const ballRef = useRef<THREE.Mesh>(null);
    const [positionY, setPositionY] = useState(5); // Start at the top
    const [velocity, setVelocity] = useState(-0.02); // Initial downward velocity
    const gravity = -0.002; // Simulated gravity
    const damping = 0.7; // Damping factor for the bounce

    useFrame(() => {
        if (ballRef.current) {
            setPositionY((prev) => {
                let newVelocity = velocity + gravity; // Apply gravity
                let newPosition = prev + newVelocity; // Update position

                // Check for bounce when hitting the ground
                if (newPosition <= -3.4) {
                    newPosition = -3.4; // Clamp to ground
                    newVelocity = -newVelocity * damping; // Reverse and damp velocity
                }

                setVelocity(newVelocity); // Update velocity
                return newPosition; // Update position
            });

            // Update the ball's position in the scene
            ballRef.current.position.y = positionY;
        }
    });

    return (
        <mesh ref={ballRef} position={[0, positionY, 0]} scale={[1, 1, 1]} >
            <sphereGeometry args={[0.3, 32, 32]} /> {/* Spherical geometry */}
            <meshStandardMaterial color="red" />
        </mesh>
    );
};

const DotsGrid: React.FC = () => {
    const dotPositions: [number, number, number][] = [ // Explicitly define positions as [x, y, z] tuples
        [-2, 0, 0], // Dot 1
        [-1, 0, 0],  // Dot 2
        [0, 0, 0],  // Dot 3
        [1, 0, 0], // Dot 4
        [2, 0, 0], // Dot 5
    ];

    return (
        <>
            {dotPositions.map((position, index) => (
                <group key={index} position={position as [number, number, number]}>
                    {/* Green border (ring) around the dot */}
                    <mesh position={[0, 0, 0]} scale={[1.4, 1.4, 1.4]}>
                        <ringGeometry args={[0., 0.20, 32]} /> {/* Ring geometry for border */}
                        <meshStandardMaterial color="green" />
                    </mesh>
                    {/* Dot */}
                    <mesh position={[0, 0, 0]}>
                        <sphereGeometry args={[0.2, 32, 32]} /> {/* Spherical geometry for the dots */}
                        <meshStandardMaterial color="hotpink" />
                    </mesh>
                </group>
            ))}
        </>
    );
};

const Overview: React.FC = () => {
    return (
        <div style={{ width: "100%", height: "100vh" }}>
            <Canvas>
                {/* Lighting */}
                <ambientLight intensity={0.5} />
                <directionalLight position={[5, 5, 5]} />

                {/* Ball */}
                <Ball />

                {/* Dots Grid */}
                <DotsGrid />
            </Canvas>
        </div>
    );
};

export default Overview;
