import React, { useEffect, useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Line } from '@react-three/drei';
import * as THREE from 'three';

const Rocket: React.FC<{ multiplier: number }> = ({ multiplier }) => {
     const ref = useRef<THREE.Group>(null!);
     const [points, setPoints] = useState([
          new THREE.Vector3(-5, -5, 0), // Left-bottom starting point
          new THREE.Vector3(-5, -5, 0), // Same as starting point initially
     ]);

     useFrame(() => {
          if (ref.current) {
               const newX = -5 + multiplier; // X-axis movement
               const newY = -5 + multiplier; // Y-axis movement
               ref.current.position.set(newX, newY, 0);

               // Update the line points to follow the rocket
               setPoints([
                    new THREE.Vector3(-5, -5, 0), // Always starts from bottom-left
                    new THREE.Vector3(newX, newY, 0),
               ]);
          }
     });

     return (
          <>
               <group ref={ref} position={[-5, -5, 0]} rotation={[0, 0, Math.PI / -4]}>
                    {/* Rocket Body */}
                    <mesh position={[0, 0, 0]}>
                         <cylinderGeometry args={[0.1, 0.1, 0.5, 32]} />
                         <meshBasicMaterial color="gray" />
                    </mesh>
                    {/* Rocket Nose */}
                    <mesh position={[0, 0.25, 0]}>
                         <coneGeometry args={[0.1, 0.2, 32]} />
                         <meshBasicMaterial color="red" />
                    </mesh>
                    {/* Rocket Fins */}
                    <mesh position={[0.1, -0.25, 0]} rotation={[0, 0, Math.PI / 4]}>
                         <boxGeometry args={[0.05, 0.2, 0.01]} />
                         <meshBasicMaterial color="blue" />
                    </mesh>
                    <mesh position={[-0.1, -0.25, 0]} rotation={[0, 0, -Math.PI / 4]}>
                         <boxGeometry args={[0.05, 0.2, 0.01]} />
                         <meshBasicMaterial color="blue" />
                    </mesh>
               </group>
               {/* Line from starting point to rocket */}
               <Line points={points} color="black" lineWidth={1} />
          </>
     );
};

const Demo2: React.FC = () => {
     const [multiplier, setMultiplier] = useState<number>(0); // Start at 0
     const [crashed, setCrashed] = useState<boolean>(false);

     useEffect(() => {
          if (!crashed) {
               const interval = setInterval(() => {
                    setMultiplier((prev) => {
                         const nextValue = prev + 0.1; // Slow increment for better visibility
                         if (nextValue >= Math.random() * 30) { // Random crash point
                              setCrashed(true);
                              clearInterval(interval);
                         }
                         return nextValue;
                    });
               }, 100); // Speed of increment

               return () => clearInterval(interval);
          }
     }, [crashed]);

     const resetGame = () => {
          setMultiplier(0); // Reset multiplier
          setCrashed(false); // Reset crash state
     };

     return (
          <div className='__game'>
               <div className='__tools'>
                    <button onClick={resetGame}>
                         Restart
                    </button>
                    {/* Display the multiplier value with 'x' suffix */}
                    <div>Multiplier: {multiplier.toFixed(1)}x</div>
               </div>

               <div className='__canvas'>
                    <Canvas style={{ backgroundColor: 'skyblue' }} orthographic camera={{ position: [0, 0, 10], zoom: 50 }}>
                         <ambientLight />
                         <pointLight position={[10, 10, 10]} />
                         <Rocket multiplier={multiplier} />
                    </Canvas>
               </div>
          </div>
     );
};

export default Demo2;
