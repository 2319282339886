import { title } from '../Config/Config';
import HelmetSEO from '../Config/HelmetSEO';
import Banner from './indexComponents/Banner';
import About from './indexComponents/About';
import Services from './indexComponents/Services';
import FavouriteWeb from './indexComponents/FavouriteWeb';
import Divider from './indexComponents/Divider';
import Faq from './indexComponents/Faq';



const Index = () => {

     return (
          <>
               <HelmetSEO
                    title={`Home | ${title}`}
                    description="Welcome to our index! Explore our amazing features and services."
                    keywords="index, thedc react, typescript, website designer, frontend developer, Portfolio website , sunil sharma," />

               {/* <Loader timer={1000} /> */}
               <Banner />
               <FavouriteWeb />
               <About />
               <Divider/>
               <Services />
               <Faq/>

          </>
     );
}

export default Index;


