import React, { useState, useEffect, useRef } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { Group } from 'three'; // Import Group type from three


const Dot: React.FC<{ position: [number, number, number]; scale: number }> = ({ position, scale }) => {
    return (
        <mesh position={position} scale={[scale, scale, scale]}>
            <sphereGeometry args={[0.1, 32, 32]} />
            <meshStandardMaterial color="hotpink" />
        </mesh>
    );
};

const DotsGrid: React.FC<{ numRows: number; isAnimating: boolean }> = ({ numRows, isAnimating }) => {
    const groupRef = useRef<Group>(null);

    useEffect(() => {
        if (groupRef.current) {
            const classList = groupRef.current?.userData.classList || [];
            groupRef.current.userData.classList = classList; // Ensure it exists
            if (isAnimating) {
                classList.push('animate-grid');
            } else {
                const index = classList.indexOf('animate-grid');
                if (index > -1) classList.splice(index, 1);
            }
        }
    }, [isAnimating]);

    const initialDots = 3; // Starting dots in the first row
    const totalHeight = numRows * 1.5; // Total height of the grid

    return (
        <group ref={groupRef}>
            {Array.from({ length: numRows }, (_, rowIndex) => {
                const dotsInRow = initialDots + rowIndex; // The number of dots increases as rowIndex increases
                const verticalPosition = (numRows - rowIndex - 1) * 1.5; // Top-to-bottom positioning
                return Array.from({ length: dotsInRow }, (_, dotIndex) => (
                    <Dot
                        key={`${rowIndex}-${dotIndex}`}
                        position={[
                            (dotIndex - (dotsInRow - 1) / 2) * 1.5, // Center horizontally
                            verticalPosition - totalHeight / 2,      // Center vertically
                            0,                                       // Z position (flat)
                        ]}
                        scale={1} // No animation scaling needed here
                    />
                ));
            })}
        </group>
    );
};

const AdaptiveZoom: React.FC<{ numRows: number }> = ({ numRows }) => {
    const { camera } = useThree();

    useEffect(() => {
        const gridHeight = numRows * 1.5; // Calculate the total grid height
        const gridWidth = (numRows + 3) * 1.5; // Approximate grid width for max dots in a row
        const maxDimension = Math.max(gridHeight, gridWidth);

        // Ensure the camera is a PerspectiveCamera
        if ('fov' in camera) {
            camera.position.set(0, 0, maxDimension * 1.2); // Move camera back to fit grid
            camera.fov = 50; // Adjust the field of view
            camera.updateProjectionMatrix();
        }
    }, [numRows, camera]);

    return null;
};



const Demo: React.FC = () => {
    const [numRows, setNumRows] = useState<number>(() => {
        const savedRows = localStorage.getItem('numRows');
        return savedRows ? parseInt(savedRows, 10) : 8;
    });
    const [isAnimating, setIsAnimating] = useState(false);

    const handleChange = (e: any) => {
        const selectedRows = parseInt(e.target.value, 10);
        setIsAnimating(true); // Trigger animation
        setTimeout(() => setIsAnimating(false), 300); // Stop animation after 300ms
        setNumRows(selectedRows);
        localStorage.setItem('numRows', selectedRows.toString()); // Save to localStorage
    };

    return (
        <div className='__top' style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            {/* Dropdown Selector */}
            <div style={{ padding: '10px', textAlign: 'center' }}>
                <label htmlFor="rows-selector" style={{ marginRight: '10px' }}>
                    Select Rows:
                </label>
                <select
                    id="rows-selector"
                    value={numRows}
                    onChange={handleChange}
                    style={{
                        padding: '5px 10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                    }}
                >
                    {Array.from({ length: 9 }, (_, i) => i + 8).map((value) => (
                        <option key={value} value={value}>
                            {value}
                        </option>
                    ))}
                </select>
            </div>

            {/* Canvas */}
            <Canvas
                style={{ flexGrow: 1 }}
                camera={{ fov: 50, near: 0.1, far: 1000, position: [0, 0, 10] }} // Explicitly set to PerspectiveCamera
            >
                <ambientLight />
                <directionalLight position={[10, 10, 10]} />
                <DotsGrid numRows={numRows} isAnimating={isAnimating} />
                <AdaptiveZoom numRows={numRows} />
            </Canvas>
        </div>
    );
};

export default Demo;
