import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BarMenu } from '../AllContent/Content';

const BottomBar = (props: any) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`__bar ${isVisible ? 'show' : 'hide'}`}>
            <div className='__center'>
                {BarMenu.map((val, index) => (
                    <div
                        className={`__item ${val.link === window.location.pathname ? '--active' : ''}`}
                        key={index}
                    >
                        {val.btn ? (
                            <button className='__link' onClick={props.handleShow}>
                                <p>{val.name}</p>
                            </button>
                        ) : (
                            <Link to={val.link} className='__link'>
                                <p>{val.name}</p>
                            </Link>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BottomBar;
