import React, { useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';

interface DotProps {
    position: [number, number, number];
}

const Dot: React.FC<DotProps> = ({ position }) => {
    return (
        <mesh position={position}>
            <sphereGeometry args={[0.1, 100, 100]} />
            <meshStandardMaterial color="black" />
        </mesh>
    );
};

const DotsGrid: React.FC = () => {
    const { viewport } = useThree(); // Get viewport dimensions
    const rows = 10; // Total rows
    const initialDots = 3; // Number of dots in the first row
    const canvasHeight = viewport.height; // Canvas height
    const padding = 0.6; // Space to add at top and bottom
    const maxSpacing = (canvasHeight - 2 * padding) / rows; // Adjust spacing to fit canvas with padding
    const dotSpacing = 0.1; // Space between dots horizontally

    const dots: JSX.Element[] = [];
    for (let row = 0; row < rows; row++) {
        const dotCount = initialDots + row; // Each row adds 1 more dot than the previous
        const yPosition = canvasHeight / 2 - padding - (row + 1) * maxSpacing; // Start from top with padding
        for (let i = 0; i < dotCount; i++) {
            const xPosition = (i - (dotCount - 1) / 2) * (maxSpacing + dotSpacing); // Center the row horizontally with additional spacing
            dots.push(<Dot key={`${row}-${i}`} position={[xPosition, yPosition, 0]} />);
        }
    }

    return <>{dots}</>;
};

const GravityBall: React.FC = () => {
    const { viewport } = useThree();
    const [position, setPosition] = useState<[number, number, number]>([0, viewport.height / 2, 0]); // Ball starts at top center
    const [velocity, setVelocity] = useState(0); // Initial velocity is 0
    const [hasBounced, setHasBounced] = useState(false); // To track if the ball has bounced
    const gravity = -0.1; // Gravity force for smooth fall
    const groundLevel = -viewport.height / 2 + 0.2; // Ground level
    const bounceVelocity = 0.3; // Upward velocity after bounce

    // Update the position based on gravity
    useFrame(() => {
        setVelocity((prevVelocity) => prevVelocity + gravity); // Apply gravity

        setPosition((prevPosition) => {
            const newY = prevPosition[1] + velocity; // Update Y position with velocity

            // If the ball is falling and reaches the ground
            if (newY <= groundLevel && !hasBounced) {
                setVelocity(bounceVelocity); // Bounce upwards
                setHasBounced(true); // Set bounce flag to true
                return [prevPosition[0], groundLevel, prevPosition[2]]; // Keep the ball at ground level
            }

            // After bouncing, stop the ball at the ground level
            if (hasBounced && newY >= groundLevel) {
                setVelocity(0); // Stop the velocity after bounce
                return [prevPosition[0], groundLevel, prevPosition[2]]; // Keep the ball at ground level after bounce
            }

            return [prevPosition[0], newY, prevPosition[2]]; // Continue falling normally
        });
    });

    return (
        <mesh position={position as [number, number, number]}>
            <sphereGeometry args={[0.2, 32, 32]} />
            <meshStandardMaterial color={hasBounced ? "yellow" : "red"} /> {/* Change color during bounce */}
        </mesh>
    );
};

const View: React.FC = () => {
    return (
        <Canvas className="__canvas" style={{ width: '100%', height: '100vh', background: 'lightblue' }}>
            <DotsGrid />
            <GravityBall />
            <ambientLight intensity={0.5} />
        </Canvas>
    );
};

export default View;
