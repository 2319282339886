import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { websites } from '../../AllContent/Data';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Loader from '../../Config/Loader';

const shuffleArray = (array: any[]) => {
     return array
          .map((item) => ({ item, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ item }) => item);
};

const HelpAi = () => {
     const [searchQuery, setSearchQuery] = useState<string>('');
     
     const filteredWebsites = websites.filter((site) =>
          site.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          site.category.toLowerCase().includes(searchQuery.toLowerCase())
     );

     const shuffledWebsites = shuffleArray(filteredWebsites);

     // Adjusted condition to check for both filteredWebsites and visibleWebsites
     const noResults = filteredWebsites.length === 0 && shuffledWebsites.length === 0;


     return (
          <>
               <Loader timer={2000} />

               <section className='ai'>
                    <ParallaxProvider>
                         <Parallax speed={5}>
                              <div className='__headings'>
                                   <div className='__container'>
                                        <div className='row'>
                                             <div className='col-md-6'>
                                                  <div className='__left'>
                                                       <h2>Explore Your Favorite {websites.length}+ Websites</h2>
                                                       <h4>Search by URL, name, category, or type of work to discover the right site or project in an instant.</h4>
                                                  </div>
                                             </div>

                                             <div className='col-md-6'>
                                                  <div className='__select'>
                                                       <input
                                                            type='search'
                                                            placeholder='Search Websites'
                                                            value={searchQuery}
                                                            onChange={(e) => setSearchQuery(e.target.value)} />
                                                  </div>
                                             </div>
                                        </div>


                                   </div>
                              </div>
                         </Parallax>

                         <div className='__container'>
                              <div className='__sec'>
                                   <div className='__cards'>
                                        <TransitionGroup className='row'>
                                             {!noResults ? (
                                                  shuffledWebsites.map((item, index) => (
                                                       <CSSTransition key={index} timeout={500} classNames='fade'>
                                                            <div className='col-xl-3 col-lg-4 col-md-6' >
                                                                 <Parallax speed={index % 2 === 0 ? 0 : 8} className='hne__ahn'>
                                                                      <div className='card-qw'>
                                                                           <Link
                                                                                to={item.url}
                                                                                className='stretched-link'
                                                                                target='_blank'
                                                                           ></Link>
                                                                           <div className='card-header'>
                                                                                <img src={item.imageUrl} alt={item.name + '-Logo'} />
                                                                           </div>
                                                                           <div className='card-body'>
                                                                                <h5>{item.name}</h5>
                                                                                <p>{item.description}</p>
                                                                           </div>
                                                                      </div>
                                                                 </Parallax>
                                                            </div>
                                                       </CSSTransition>
                                                  ))
                                             ) : (
                                                  <div className='col-12'>
                                                       <div className='__section-height'>
                                                            <p className='text-center'>No results found.</p>
                                                       </div>
                                                  </div>
                                             )}
                                        </TransitionGroup>

                                   </div>
                              </div>
                         </div>
                    </ParallaxProvider>
               </section>
          </>
     );
};

export default HelpAi;